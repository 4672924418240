<template>
    <div>
        <div class="page-title">记账</div>

        <div>
            <a-table
                    class="stock-table"
                    :columns="table.columns"
                    :data-source="table.data"
                    :loading="table.loading"
                    :pagination="table.pagination"
                    :rowKey="record => record.am_id || record.key"
                    @change="tableChanged"
                    bordered
            >
                <div slot="idx" slot-scope="val,item">
                    {{ (query.page - 1) * 20 + table.data.indexOf(item) + 1 }}
                </div>

                <div slot="time" slot-scope="time">
                    {{ formatTime(time) }}
                </div>

                <div slot="transaction_id" slot-scope="transactionId">

                    <a-tooltip>
                        <template slot="title">
                            <div style="width: 150px">{{ transactionId }}</div>
                        </template>
                        {{ transactionId.substring(16, transactionId.length) }}
                    </a-tooltip>

                </div>

                <div slot="payer" slot-scope="val,item">
                    <router-link :to="`/ast/members/${item.id}`">
                        <div class="member-card">
                            <div><img :src="item.avatar" alt=""></div>
                            <span class="member-name">{{ item.mark_name || item.name }}</span>
                        </div>
                    </router-link>
                </div>

                <div slot="money" slot-scope="money">
                    ￥{{ money }}
                </div>

                <div slot="income" slot-scope="money,item">
                    <template v-if="money > 0">
                        <router-link :to="`/ast/shop/bill/income/${item.am_id}`">
                            ￥{{ money }}
                        </router-link>
                    </template>
                    <template v-else>￥0.00</template>

                </div>

                <div slot="expenditure" slot-scope="money,item">
                    <template v-if="money > 0">
                        <router-link :to="`/ast/shop/bill/expenditure/${item.am_id}`">
                            ￥{{ money }}
                        </router-link>
                    </template>
                    <template v-else>￥0.00</template>
                </div>

                <div slot="remark" slot-scope="remark">
                    <template v-if="remark.length > 10">
                        <a-tooltip>
                            <template slot="title">
                                <div style="width: 150px">{{ remark }}</div>
                            </template>
                            <div class="remark">{{ remark.substring(0, 10) }}...</div>
                        </a-tooltip>
                    </template>

                    <template v-else>
                        <div class="remark">{{ remark }}</div>
                    </template>
                </div>

                <div slot="op" slot-scope="val,item">
                    <button @click="showExpenditureArea(item)" class="btn">转账</button>
                </div>

                <div class="text-center" slot="level_name" slot-scope="val,item">
                    <template v-if=" item.level.id >  1 ">
                        <div class="level-card">
                            <span v-if="item.level.sort ">Lv.{{ item.level.sort }}</span>{{ item.level.name }}
                        </div>
                    </template>
                    <template v-else>{{ item.level.name }}</template>
                </div>

                <div slot="selection" slot-scope="val,item">

                    <div @click="selectMember(item)" v-if="item.balance > 0 ">

                        <template v-if=" resetData.member_ids.indexOf(item.id) >= 0 ">
                            <div class="bill-radio selected"></div>
                        </template>
                        <template v-else>
                            <div class="bill-radio"></div>
                        </template>

                    </div>


                </div>

            </a-table>

            <div v-if=" resetData.member_ids.length > 0 ">
                <button @click=" showResetModal = true " class="btn">剩余清零</button>
            </div>
        </div>

        <at-modal v-model="showExpenditureModal" title="转账" @ok="confirmBill">
            <div>
                <div class="remark-item">
                    <div class="remark-title">转款金额</div>
                    <div>
                        <at-input v-model="billData.amount" unit="￥" class="full-with"/>
                    </div>
                </div>
                <div class="remark-item">
                    <div>
                        <a-radio-group v-model="billData.usage">
                            <a-radio value="门店消费">
                                门店消费
                            </a-radio>
                            <a-radio value="提现转账">
                                提现转账
                            </a-radio>
                            <a-radio value="其他变更">
                                其他变更
                            </a-radio>
                        </a-radio-group>
                    </div>
                </div>
                <div class="remark-item">
                    <div class="remark-title">备注</div>
                    <div>
                        <a-textarea :maxLength="30" v-model="billData.remark" class="bill-remark"></a-textarea>
                    </div>
                </div>
            </div>
        </at-modal>

        <at-modal v-model="showResetModal" title="剩余清零" @ok="confirmRest">
            <div>
                <div class="mb-2 reset-tips">
                    当前已选择{{ resetData.member_ids.length }}个用户.
                </div>
                <div class="remark-item">
                    <div class="remark-title">备注</div>
                    <div>
                        <a-textarea :maxLength="30" v-model="resetData.remark" class="bill-remark"></a-textarea>
                    </div>
                </div>
            </div>
        </at-modal>
    </div>
</template>

<script>
import api from "../../../repo/api";
import moment from 'moment';
import AtModal from "../../../components/AtModal";
import AtInput from "../../../components/AtInput";

export default {
    name: "GoodsSync",
    components: {AtInput, AtModal},
    data() {

        return {
            table: {
                columns: [],
                loading: false,
                data: [],
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 20
                },
            },
            showResetModal: false,
            list: [],
            query: {
                page: 1,
                order_by: 'time',
                order_type: 'desc',
                levels: []
            },
            selectedItem: null,
            showExpenditureModal: false,
            billData: {
                am_id: 0,
                amount: '',
                remark: '',
                usage: '',
            },
            resetData: {
                member_ids: [],
                remark: '换季清零',
            },
            memberLevels: [],
        }
    },
    mounted() {
        this.getLevels()
    },
    methods: {
        getLevels() {
            api.get('/ast-app/member-levels', (data) => {

                this.memberLevels = data.data
                this.memberLevels.push('游客')

                this.buildColumns()
                this.getFirstList()
            })
        },
        selectMember(member) {
            let idx = this.resetData.member_ids.indexOf(member.id)

            if (idx >= 0) return this.resetData.member_ids.splice(idx, 1)

            this.resetData.member_ids.push(member.id)
        },
        buildColumns() {

            let levels = []
            this.memberLevels.forEach(level => {
                levels.push({text: level, value: level},)
            })

            this.table.columns = [
                {
                    title: '',
                    dataIndex: 'selection',
                    scopedSlots: {customRender: 'selection'},
                },
                {
                    title: '用户ID',
                    dataIndex: 'member_id',
                    align: 'center',
                },
                {
                    title: '记账用户',
                    dataIndex: 'payer',
                    align: 'left',
                    scopedSlots: {customRender: 'payer'},
                },
                {
                    title: '会员等级',
                    dataIndex: 'level_name',
                    scopedSlots: {customRender: 'level_name'},
                    filters: levels,
                },
                {
                    title: '总记账金额',
                    dataIndex: 'income',
                    align: 'center',
                    scopedSlots: {customRender: 'income'},
                    sorter: true
                },
                {
                    title: '待转款金额',
                    dataIndex: 'balance',
                    align: 'center',
                    scopedSlots: {customRender: 'money'},
                    sorter: true
                },
                {
                    title: '已转款金额',
                    dataIndex: 'expenditure',
                    align: 'center',
                    scopedSlots: {customRender: 'expenditure'},
                    sorter: true
                },
                {
                    title: '操作',
                    dataIndex: 'op',
                    align: 'center',
                    scopedSlots: {customRender: 'op'},
                }
            ]
        },
        showExpenditureArea(item) {
            this.selectedItem = item

            this.billData = {
                am_id: item.am_id,
                amount: '',
                remark: '',
                usage: '门店消费',
            }

            this.showExpenditureModal = true
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        getList() {

            this.table.loading = true

            api.get('/ast-cashier/bills', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },

        tableChanged(pagination, filter, sorter) {
            this.query.order_by = sorter.field || 'time'
            this.query.order_type = sorter.order === 'descend' ? 'desc' : 'asc'
            this.query.page = pagination.current
            this.query.levels = filter.level_name

            this.getList()
        },
        confirmRest() {
            this.$loading.show()

            api.post('/ast-cashier/reset-bill', this.resetData, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {
                    this.showResetModal = false
                    this.resetData.member_ids = []
                    this.getList()
                }

            });
        },
        confirmBill() {
            this.$loading.show()

            api.post('/ast-cashier/add-bill', this.billData, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {
                    this.showExpenditureModal = false
                    this.getList()
                }

            });

        },
    }
}
</script>

<style lang="less" scoped>
.mt16 {
    margin-top: 16px;
}

.member-card {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;

    img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }

    .member-name {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
}

.cj {
    color: #FBB03B;
}

.yc-only .member-name {
    color: #FBB03B;
}

.remark {
    width: 100px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
}

.member-id {
    height: 20px;
    text-align: center;
    background-color: #ddd;
    font-size: 12px;
    margin-top: 5px;
    color: white;
    position: relative;
    padding: 2px 2px 2px 10px;

    &:before {
        position: absolute;
        left: 5px;
        content: ' ID ';
    }
}

.bill-remark {
    width: 100%;
    height: 120px;
}

.remark-title {
    margin-bottom: 16px;
}

.remark-item {
    margin-bottom: 24px;
}

.level-card {
    border-radius: 8px;
    border: 1px solid #EDC483;
    color: #EDC483;
    text-align: center;
    display: inline-block;
    padding: 2px 6px;
}

.bill-radio {
    width: 16px;
    height: 16px;
    border: 1px solid #333333;
    cursor: pointer;

    &.selected {
        background-image: url("/assets/icons/stock-selected.png");
        background-size: cover;
    }
}

.reset-tips {
    font-size: 16px;
    color: orange;
}

</style>